:root {
	--background-color: #FAFAFA;
	--foreground-primary-color: #222222;
	--foreground-secondary-color: #002aff;
	background: var(--background-color);
	color: var(--foreground-primary-color);
}

footer {
	margin-top: 2rem;
	padding: 1rem;
}

.App {
 font-family: Arial, sans-serif;
 text-align: center;
}

.company-name {
 font-size: 3em;
 margin-bottom: 0;
 color: var(--foreground-secondary-color);
}

.company-address {
 font-size: 1.5em;
 margin-top: 0;
 color: var(--foreground-secondary-color);
}

footer {
 padding: 20px;
 background-color: #f8f9fa;
}
